import { useRouter } from 'next/router'
import { useMemo } from 'react'

interface IUseFooterCore {
  (): {
    isSchool: boolean
    showFreeLessonButton: boolean
  }
}

export const useFooterCore: IUseFooterCore = () => {
  const { pathname } = useRouter()
  const siteNames = [
    'for-abiturients/ege',
    'for-abiturients/oge',
    'for-abiturients/school',
    'for-abiturients/ege-10',
  ]
  const isSchool = siteNames.some((name) => pathname.includes(name))

  const showFreeLessonButton = useMemo(() => {
    const paths = ['/uz/[id]', '/program/[id]']

    if (pathname) {
      return paths.every((str) => !pathname.includes(str))
    }

    return true
  }, [pathname])

  return {
    isSchool,
    showFreeLessonButton,
  }
}
