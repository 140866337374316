import { profileSlice } from './index'
import { IProfileShort } from './short/types'
import { ILocation } from '../location/types'
import { IEnrolleeInfo, ILearningInterest } from '../crm/institutions/id/leads/types'

export interface IProfileState {
  [key: string]: ReturnType<typeof profileSlice.reducer>
}

export interface IProfile extends IProfileShort {
  location: Pick<ILocation, 'id' | 'name'>
  /** Вопрос тут по типу аттестата. Там доп. поля идут. Как их сюда вставить, наследуя другой интерфейс?
   * export interface ISchoolAttestat {
        id: number
        score: number
      }
   А приходит:
   "schoolAttestat": {
      "score": 0,
      "scoreType": {
        "id": 0,
        "min": 0,
        "max": 0,
        "name": "string"
      }
    }
   *
   * */
  enrolleeInfo: Pick<
    IEnrolleeInfo,
    | 'year'
    | 'educationLevel'
    | 'schoolAttestat'
    | 'exams'
    | 'achievements'
    | 'readyForAdditionalExam'
  > | null
  learningInterest: Pick<
    ILearningInterest,
    'isAbroadEducation' | 'programTypes' | 'trainingFormTypes' | 'tags' | 'locations'
  > | null
  educations: IEducation[]
  confirmationKey: string
  messengers: IMessenger[]
  isParent: boolean
  plannedExam?: 'ege' | 'oge' | null
  parentPhone: string | null
  parentName: string | null
}

interface IMessenger {
  channelId: number
  messengerType: keyof typeof EMessengerType
  chatId: number
  isAllowed: boolean
}

export enum EMessengerType {
  vk = 'vk',
  telegram = 'telegram',
  whatsapp = 'whatsapp',
}

export enum EMessengerChannel {
  vk_ucheba = 1,
  telegram_ucheba = 2,
  whatsapp_ucheba = 3,
  telegram_platform = 4,
}

interface IEducation {
  id: number
  fromYear: number | null
  toYear: number | null
  institutionName: string | null
  location: ILocation
  programType: {
    name: string
    value: number
  } | null
}
