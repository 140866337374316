import { EInstitutionType } from '@ucheba/store/institutions/item/types'

/** Начало пути для ссылок логотипов учебных заведений из базы */
export const logoUrl = 'https://www.ucheba.ru/pix/logo_cache/'

/** Название куки авторизации */
export const authCookieToken = 'uchru_token'

/** Название куки уведомлений */
export const notificationsCookie = 'notifications'

/** Название куки нотисов */
export const noticeCookie = 'notices'

/** Основные query-параметры */
export const coreQuery = {
  /** Урл откуда пришел пользователь и куда его вернем после определенных действий */
  redirect: 'redirect',
  /** Альтернативный урл откуда пришел пользователь? */
  referer: 'referer',
}

/** Урл настроек профиля в личном кабинете */
export const profileSetupBaseUrl = '/profile/setup/'

/** Путь для редиректа, если логинится/регистрируется как УЗ */
export const defaultInstitutionlocation = '/my/uz'

/** Локация "Россия" для поиска специальностей */
export const allRussiaLocationId = 9653

/** Название куки региона (локации) */
export const locationIdsCookie = 'locationIds'

/** ID региона Россия */
export const russiaLocationId = 9653

/** Ключ от апи Яндекс карт */
export const yaMapApiKey = 'd635ff96-0d8a-408e-bbca-9298df3d2460'

/** Ключ от апи Гугл карт */
export const gMapApiKey = 'AIzaSyDNr_IGC_jSFPaYJ0mRlkGk_993_gQ6xdg'

/** Кука уникального айдишника пользователя для рекламы */
export const advUserIdCookie = 'advUserId'

export const PROGRAM_TYPE_FOR_BANNER = {
  [EInstitutionType.University]: [1002, 3, 4],
  [EInstitutionType.College]: [1001],
  [EInstitutionType.School]: [1000],
  [EInstitutionType.MBA]: [1005],
  [EInstitutionType.TrainingCenter]: [1004],
  [EInstitutionType.TrainingCourses]: [1003],
}

export const SCHOOL_SITE_NAME = 'ucheba_school'
export const UCHEBA_SITE_NAME = 'ucheba'

export const tmpToken = 'tmp_token'

export const AGREEMENTS_LINKS = {
  personalData:
    'https://docs.google.com/document/d/1q4ecLMJ0r0Z4ha9Nn_ZkI-lvvvHzPRis/edit',
  agreementsForMain: 'https://www.ucheba.ru/agreement',
  agreementsForTeachers:
    'https://docs.google.com/document/d/1WlxO7WzPL1Tw5KqPPJe50HuHQnQ1T8wc/edit',
}
