const subjects = [
  {
    id: 1,
    name: 'русский язык',
    slug: 'russian',
    dativeName: 'русскому языку',
    uchebaId: 2,
  },
  {
    id: 2,
    name: 'математика',
    slug: 'mathematics',
    dativeName: 'математике',
    uchebaId: 1,
  },
  {
    id: 7,
    name: 'обществознание',
    slug: 'social',
    dativeName: 'обществознанию',
    uchebaId: 7,
  },
  {
    id: 8,
    name: 'биология',
    slug: 'biology',
    dativeName: 'биологии',
    uchebaId: 9,
  },
  {
    id: 9,
    name: 'информатика',
    slug: 'informatics',
    dativeName: 'информатике',
    uchebaId: 6,
  },
  {
    id: 3,
    name: 'физика',
    slug: 'physics',
    dativeName: 'физике',
    uchebaId: 3,
  },
  {
    id: 5,
    name: 'история',
    slug: 'history',
    dativeName: 'истории',
    uchebaId: 49,
  },
  {
    id: 6,
    name: 'английский язык',
    slug: 'english',
    dativeName: 'английскому языку',
    uchebaId: 32,
  },
  {
    id: 4,
    name: 'химия',
    slug: 'chemistry',
    dativeName: 'химии',
    uchebaId: 4,
  },
  {
    id: 10,
    name: 'литература',
    slug: 'literature',
    dativeName: 'литературе',
    uchebaId: 12,
  },
]

const schoolPages = [
  `/for-abiturients/ege`,
  `/for-abiturients/ege-10`,
  `/for-abiturients/oge`,
  `/for-abiturients/ege/[subject]`,
  `/for-abiturients/ege-10/[subject]`,
  `/for-abiturients/oge/[subject]`,
]

subjects.forEach((subject) => {
  schoolPages.push(`/for-abiturients/ege/${subject.slug}`)

  schoolPages.push(`/for-abiturients/ege-10/${subject.slug}`)

  schoolPages.push(`/for-abiturients/oge/${subject.slug}`)
})

export { schoolPages }
