import apiThunk, { api } from '@ucheba/utils/helpers/api'
import keyGenerator from '@ucheba/utils/helpers/key-generator/index'
import { sliceName } from './constants'

const sendCode = apiThunk(`${sliceName}/code`, async (params) => {
  /* Убираю '+' и все остальные символы не-цифры */
  const { phone, isForceSms } = params.data
  const data = {
    phone,
    isForceSms,
  }

  const response = await api.post(`/auth/sendcode`, data, params)

  return response.data
})

const sendCodeCall = apiThunk(`${sliceName}/codeCall`, async (params) => {
  const phone = params?.data.phone
  const data = {
    phone,
    key: keyGenerator(phone),
  }

  const response = await api.post(`/auth/sendcode/phone/flashcall`, data, params)

  return response.data.timeout
})

export default { sendCode, sendCodeCall }
