import { Component, createElement } from 'react'
import { NextPage, NextPageContext } from 'next'
import * as analytics from '../helpers/analytics/victorycorp'
import { isDev, isLocal } from '../helpers/core'

const withVictoryCorp =
  (Router?: any, pathnames?: string[]) =>
  (Page: NextPage<any>): NextPage<any> => {
    class WithVkPixel extends Component {
      // eslint-disable-next-line @typescript-eslint/ban-types
      static getInitialProps: (context: NextPageContext) => {} | Promise<{}>

      componentDidMount(): void {
        const shouldntTrack = isLocal || isDev

        if (shouldntTrack) return

        if (!pathnames || pathnames.includes(window.location.pathname)) {
          analytics.init()
        }

        // listen route changes
        if (Router && Router.events && typeof Router.events.on === 'function') {
          Router.events.on('routeChangeComplete', () => {
            if (!pathnames || pathnames.includes(window.location.pathname)) {
              const scriptElement = document.getElementById('victory-corp')

              if (scriptElement) {
                scriptElement.remove()
              }

              analytics.init()
            }
          })
        }
      }

      render(): JSX.Element {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return createElement(Page, this.props)
      }
    }

    if (Page.getInitialProps) {
      WithVkPixel.getInitialProps = Page.getInitialProps
    }

    return WithVkPixel
  }

export default withVictoryCorp
