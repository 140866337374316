// eslint-disable-next-line max-classes-per-file
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
// import { throttleAdapterEnhancer } from 'axios-extensions'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { NextPageContext } from 'next'
import { v4 as uuidv4 } from 'uuid'
import { EHttpStatusCode } from '../../constants/api'
import { getParsedCookies, isDev } from '../core'
import { IApi, IApiConfig, IApiThunk } from './types'
import { authCookieToken, tmpToken } from '../../constants/core'

type IReqLoggerRequests = Array<{
  id: string
  config: AxiosRequestConfig
  error: AxiosError | null
  res: AxiosResponse | null
  start: number | null
  end: number | null
  time: string | null
}>

export const apiLogger = new (class {
  public requests: IReqLoggerRequests = []

  set(
    config: IApiConfig | null,
    response?: AxiosResponse | null,
    error?: AxiosError | null
  ): void {
    if (config) {
      const id = uuidv4()

      this.requests.push({
        id,
        config,
        res: null,
        start: Date.now(),
        end: null,
        time: null,
        error: null,
      })
    }

    if (response) {
      this.requests = this.requests.map((el) => {
        if (response.config.url === el.config.url) {
          const endTime = Date.now()
          const time = typeof el.start === 'number' ? endTime - el.start : null

          return {
            ...el,
            end: endTime,
            res: response,
            time: typeof time === 'number' ? `${time.toFixed(2)}ms` : null,
          }
        }

        return el
      })
    } else if (error) {
      this.requests = this.requests.map((el) => {
        if (error.response?.config.url === el.config.url) {
          const endTime = Date.now()
          const time = typeof el.start === 'number' ? endTime - el.start : null

          return {
            ...el,
            end: endTime,
            error,
            time: typeof time === 'number' ? `${time.toFixed(2)}ms` : null,
          }
        }

        return el
      })
    }
  }

  get(): IReqLoggerRequests {
    return this.requests
  }

  log(): void {
    let log = `Requests logger:`

    this.requests.forEach((req, key) => {
      log += `[${key + 1}] ✅ ${req.config.url} succeeded in ${req.time}; `
    })

    console.log(log)
  }
})()

/** Подключение адаптеров axios
 * Подробнее здесь: https://github.com/kuitos/axios-extensions
 */
const { adapter } = axios.defaults
// ? throttleAdapterEnhancer(axios.defaults.adapter)
// : undefined

/** Создание инстанса api с первичными настройками
 * Подробнее: https://github.com/axios/axios#creating-an-instance
 */
export const api: IApi = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
  },
  baseURL: process.env.NEXT_PUBLIC_API_URL || 'https://api.ucheba.ru/v1/',
  adapter,
})

/** Перехватывание запросов в api
 * Подробнее: https://github.com/axios/axios#interceptors
 */
api.interceptors.request.use(
  (apiConfig: IApiConfig): IApiConfig | Promise<IApiConfig> => {
    // Делать что-то прежде чем запрос будет отправлен
    // если дополнительные параметры (props) существуют (куки, к примеру)
    const config = { ...apiConfig }
    const { props } = config

    apiLogger.set(config)

    /** Удаляем не нужны данные для методов */
    switch (config.method) {
      case 'get':
        delete config.data
        break
      case 'post':
      case 'patch':
      case 'delete':
        delete config.params
        break
      default:
    }

    if (props) {
      const propsCookie = props.cookie

      if (propsCookie[authCookieToken]) {
        config.headers['X-Auth-Token'] = propsCookie[authCookieToken]
      } else if (propsCookie[tmpToken]) {
        config.headers['X-Auth-Token'] = propsCookie[tmpToken]
      }
    }

    if (isDev) {
      // console.log('Запрос отправлен: ', config)
    }

    return config
  },
  (error) => {
    // Делать что-то если запрос пришел с ошибкой
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => {
    apiLogger.set(null, response)

    return response
  },
  (error) => {
    apiLogger.set(null, null, error)

    return Promise.reject(error)
  }
)

export const getHttpStatusText = (code: number): string => {
  const STATUS_CODES = {
    [EHttpStatusCode.UNAUTHORIZED]: 'Вам необходимо авторизоваться',
    [EHttpStatusCode.FORBIDDEN]: 'Доступ запрещен',
    [EHttpStatusCode.NOT_FOUND]: 'Не найдено',
  }
  return STATUS_CODES[code] || 'Что-то пошло не так'
}

const apiThunk: IApiThunk = (thunkName, requestFunction) => {
  return createAsyncThunk(thunkName, async ({ ctx, data }, thunkApi) => {
    try {
      const parsedCookie = getParsedCookies(ctx)
      const token = parsedCookie?.[authCookieToken] || ''

      const params = {
        ctx,
        data: data || {},
        params: data || {},
        props: {
          cookie: {
            ...parsedCookie,
            [authCookieToken]: token,
          },
        },
      }

      return await requestFunction(params)
    } catch (err) {
      console.error('catch error', err)

      if (err.response) {
        return thunkApi.rejectWithValue({
          name: err.response.statusText,
          message: err.response.data.message || '',
          debugMessage: err.response.data.debugMessage || '',
          formErrors: err.response.data.formErrors || '',
          code: err.response.status,
        })
      }

      return {}
    }
  })
}

interface ISendRequestProps {
  url: string
  method?: 'get' | 'post' | 'delete' | 'patch'
  data?: Record<string, any>
  ctx?: NextPageContext | null
}

interface ISendRequestResponse<T> {
  data: T
  status: number
  statusText: string
  headers: any
  config: Record<string, any>
  request?: any
}

interface ISendRequestError<T = any> {
  config: AxiosRequestConfig
  code?: string
  request?: any
  response?: ISendRequestResponse<T>
  isAxiosError: boolean
  toJSON: () => object
}

interface ISendRequest<T> {
  (props: ISendRequestProps): ISendRequestResponse<T> | ISendRequestError<T>
}

export const sendRequest: ISendRequest = async (props) => {
  const { url, method = 'get', data, ctx } = props || {}

  let params = {} as Record<string, any>

  try {
    const parsedCookie = getParsedCookies(ctx)
    const token = parsedCookie?.[authCookieToken] || ''

    params = {
      ctx,
      data: data || {},
      params: data || {},
      props: {
        cookie: {
          ...parsedCookie,
          [authCookieToken]: token,
        },
      },
    }
  } catch (err) {
    console.error('error', err)
  }

  let response = null as T | null | undefined

  try {
    switch (method) {
      case 'post':
        response = await api.post(url, params?.data, params)

        break

      case 'patch':
        response = await api.patch(url, params?.data, params)

        break

      case 'delete':
        response = await api.delete(url, params)

        break

      default:
        response = await api.get(url, params)
    }
  } catch (err) {
    console.error('catch error', err)

    return err
  }

  return response
}

type AsyncFunction<Args extends any[], Response> = (...args: Args) => Promise<Response>

export const logRequestTime = <Args extends any[], Response>(
  requestFunction: AsyncFunction<Args, Response>,
  label: string = 'Backend request'
): AsyncFunction<
  Args,
  {
    response: Response
    duration: number
  }
> => {
  return async (
    ...args: Args
  ): Promise<{
    response: Response
    duration: number
  }> => {
    const startTime = Date.now()

    try {
      const result = await requestFunction(...args)
      const duration = Date.now() - startTime

      console.log(`✅ ${label} succeeded in ${duration}ms`)
      return {
        response: result,
        duration,
      }
    } catch (error) {
      const duration = Date.now() - startTime

      console.error(`❌ ${label} failed after ${duration}ms`, error)
      throw error
    }
  }
}

export class RequestTimeLogManager {
  private logs: string[] = []

  private duration: number = 0

  add = async <Response>(
    requestFunction: () => Promise<Response>,
    label: string
  ): Promise<Response> => {
    const startTime = Date.now()

    try {
      const result = await requestFunction()
      const duration = Date.now() - startTime

      this.duration += duration
      this.logs.push(`✅ ${label} succeeded in ${duration}ms`)

      return result
    } catch (error) {
      const duration = Date.now() - startTime

      this.duration += duration
      this.logs.push(`❌ ${label} failed after ${duration}ms`)

      throw error
    }
  }

  callLog(label: string): void {
    let log = ``

    log += `${label} (all requests completed in ${this.duration}ms): `

    this.logs.forEach((str, key) => {
      log += `[${key + 1}]:${str}; `
    })

    this.logs = []
    this.duration = 0

    console.log(log)
  }
}

export default apiThunk
