import { useSelector } from 'react-redux'
import { profileSelectors } from '@ucheba/store/profile'
import { useMemo } from 'react'

/** Хук проверяет дал ли пользователь разрешение писать в один из переданных мессенджеров  */
export const useAllowedChannels = (msn: number[]): boolean => {
  const profile = useSelector(profileSelectors.entity)

  return useMemo(() => {
    if (profile) {
      const { messengers } = profile

      if (!messengers.length || !msn?.length) return false

      return msn.some((channelIdRequired) =>
        messengers.some(
          ({ channelId, isAllowed }) => isAllowed && channelId === channelIdRequired
        )
      )
    }

    return false
  }, [profile, msn])
}
