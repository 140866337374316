import React, { useEffect, useMemo, useRef } from 'react'
import NextNprogress from 'nextjs-progressbar'
import { YMInitializer } from 'react-yandex-metrika'
import App, { AppContext, AppProps } from 'next/app'
import { ThemeProvider } from 'styled-components'
import GlobalStyles from '@ucheba/ui/core/GlobalStyles'
import { Router, useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import withGa from '@ucheba/utils/hocs/withGa'
import { disableReactDevTools, getCookieDomain, isProd } from '@ucheba/utils/helpers/core'
import { isTouch as isTouchUtil } from '@ucheba/utils/helpers/core/server'
import { coreActions, coreSelectors } from '@ucheba/store/core'
import { EColor } from '@ucheba/utils/helpers/styles/variables'
import redirectMiddleware from '@ucheba/utils/middlewares/redirectMiddleware'
import authMiddleware from '@ucheba/utils/middlewares/authMiddleware'
import { analyticsIds } from '@ucheba/utils/helpers/analytics/constants'
import useUserTags from '@ucheba/utils/hooks/useUserTags'
import defineLocationMiddleware from '@ucheba/utils/middlewares/defineLocationMiddleware'
import { DefaultSeo } from 'next-seo'
import enableNotifications from '@ucheba/utils/middlewares/enableNotifications'
import defineAdvUserId from '@ucheba/utils/middlewares/defineAdvUserId'
import { enableRouteChangeEvents } from '@ucheba/utils/helpers/analytics/events/enableRouteChangeEvents'
import withGTM from '@ucheba/utils/hocs/withGTM'
import useViewsTracker from '@ucheba/utils/hooks/useViewsTracker'
import ErrorBoundary from '@ucheba/utils/errors/ErrorBoundary'
import { institutionsProgramIdSelectors } from '@ucheba/store/institutions/programs/programId'
import { institutionItemSelectors } from '@ucheba/store/institutions/item'
import useVisitsTracker from '@ucheba/utils/hooks/useVisitsTracker'
import { SCHOOL_SITE_NAME, UCHEBA_SITE_NAME } from '@ucheba/utils/constants/core'
import { siteNameActions } from '@ucheba/store/siteName'
import { RequestTimeLogManager } from '@ucheba/utils/helpers/api'
import { timingTelemetry } from '@ucheba/utils/helpers/services/timingTelemetry'
import withVictoryCorp from '@ucheba/utils/hocs/withVictoryCorp'
import SEO from '../../next-seo.config'
import themeProject from '../theme'
import { wrapper } from '../store/store'
import { Layout } from '../components/Layout'
import { headerActions, headerSelectors } from '../components/Header/store'
import { footerActions, footerSelectors } from '../components/Footer/store'
import { ChooseLocation } from '../components/ChooseLocation'
import { DefineLocationsNotice } from '../components/DefineLocationNotice'
import VkPermissionNotice from '../components/VkPermissionNotice'
import BannersNotice from '../components/BannersNotice/desktop'
import { schoolPages } from '../../utils/helpers/isSchoolPage'

enableRouteChangeEvents()

// блокируем на проде react devtools
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

const VisitTrackerComponent = (props: { siteName: string }): null => {
  const { siteName } = props
  const router = useRouter()
  const isTouch = useSelector(coreSelectors.isTouch)
  const platform = useMemo(() => (isTouch ? 'web_mobile' : 'web_desktop'), [isTouch])
  const routerObserver = useRef<Array<any>>([])

  useEffect(() => {
    const handler = (_url: string, { shallow }): void => {
      if (shallow) {
        return
      }

      routerObserver.current.forEach((callback) => callback())
    }

    router.events.on('routeChangeComplete', handler)
    // eslint-disable-next-line consistent-return
    return () => {
      router.events.off('routeChangeComplete', handler)
    }
  }, [])

  useVisitsTracker(siteName, getCookieDomain(), platform, routerObserver.current)
  return null
}

const CustomApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  const isLayout = useSelector(coreSelectors.isLayout)
  const isTouch = useSelector(coreSelectors.isTouch)
  const program = useSelector(institutionsProgramIdSelectors.entity)
  const currentInstitution = useSelector(institutionItemSelectors.currentInstitution)
  const router = useRouter()
  const { pathname } = router
  const data: Record<string, any> = {}
  const dispatch = useDispatch()

  // если страница УЗ то добавляем доп параметры
  if (pathname.includes('/uz') && currentInstitution) {
    if (currentInstitution.parentType?.value === 1) {
      data.institution_id = currentInstitution.parent?.id
      data.faculty_id = currentInstitution.id
    } else {
      data.institution_id = currentInstitution.id
      data.faculty_id = null
    }
  }

  // если страница программы то добавляем доп параметры
  if (pathname.includes('/program') && program) {
    data.program_id = program.id
    data.institution_id = program.institution?.id || null
    data.faculty_id = program.faculty?.id || null
  }
  const platform = useMemo(() => (isTouch ? 'web_mobile' : 'web_desktop'), [isTouch])
  const siteName = useMemo(() => {
    let result
    if (
      (pathname.includes('/for-abiturients/ege') ||
        pathname.includes('/for-abiturients/oge') ||
        pathname.includes('/for-abiturients/school') ||
        pathname.includes('/for-abiturients/prof') ||
        pathname.includes('/for-abiturients/admission-support') ||
        pathname.includes('/for-abiturients/free-lessons')) &&
      !pathname.includes('/for-abiturients/ege/select-vuz')
    ) {
      result = SCHOOL_SITE_NAME
    } else {
      result = UCHEBA_SITE_NAME
    }
    dispatch(siteNameActions.setSiteName(result))
    return result
  }, [dispatch, pathname])
  useUserTags()
  useViewsTracker(siteName, platform, data)

  const isShowDefineLocationsNotice = useMemo(() => {
    return (
      !pathname.includes('/for-abiturients/ege/express') &&
      !pathname.includes('/for-teachers')
    )
  }, [pathname])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const SeoBlock = <DefaultSeo {...SEO} />

  const theme = useMemo(() => {
    return {
      mode: 'default',
      ...{ isTouch, ...themeProject.variables },
    }
  }, [isTouch])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <VisitTrackerComponent siteName={siteName} />
        {SeoBlock}

        {/* Google Analytics */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${analyticsIds.ga}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
            `,
          }}
        />

        {/* Yandex */}
        {isProd && (
          <YMInitializer
            accounts={[analyticsIds.ya]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              trackHash: true,
              webvisor: true,
            }}
            version='2'
          />
        )}

        <GlobalStyles />

        <NextNprogress
          color={EColor.blue}
          startPosition={0.75}
          stopDelayMs={300}
          height={1}
        />

        <Layout isLayout={isLayout} isTouch={isTouch}>
          <Component {...pageProps} />
        </Layout>

        <ChooseLocation isTouch={isTouch} />

        <VkPermissionNotice
          isTouch={isTouch}
          blockedPaths={[
            '/events/[id]/registration',
            '/for-abiturients/planner',
            '/events/engineering-test',
            '/events/abiturients-gide',
            '/questions/[questionId]',
            // eslint-disable-next-line sonarjs/no-duplicate-string
            '/for-teachers',
          ]}
        />

        {isShowDefineLocationsNotice && <DefineLocationsNotice isTouch={isTouch} />}

        {isShowDefineLocationsNotice && <BannersNotice />}
      </ThemeProvider>
    </ErrorBoundary>
  )
}

CustomApp.getInitialProps = wrapper.getInitialAppProps(
  (store) =>
    async (appContext: AppContext): Promise<any> => {
      timingTelemetry.start('AppPage getInitialProps')
      const startTime = Date.now()
      const { ctx } = appContext
      ctx.store = store

      const logsManager = new RequestTimeLogManager()

      let isTouch = false

      if (ctx.pathname.includes('/uz/[id]')) {
        await logsManager.add(async () => {
          await redirectMiddleware(ctx)
        }, `redirectMiddleware`)

        await logsManager.add(async () => {
          await authMiddleware(ctx, store)
        }, `authMiddleware`)

        await logsManager.add(async () => {
          await defineLocationMiddleware(ctx, store)
        }, `defineLocationMiddleware`)

        await logsManager.add(async () => {
          await enableNotifications(ctx, store)
        }, `enableNotifications`)

        await logsManager.add(async () => {
          isTouch = await isTouchUtil(ctx)
        }, `isTouchUtil`)
      } else {
        await redirectMiddleware(ctx)
        await authMiddleware(ctx, store)
        await defineLocationMiddleware(ctx, store)
        await enableNotifications(ctx, store)
        isTouch = await isTouchUtil(ctx)
      }

      defineAdvUserId(ctx, store)

      const { dispatch } = store

      // Берем состояния стейтов до изменений страницы
      const startHeaderChangedId = headerSelectors.changedId(store.getState())
      const startFooterChangedId = footerSelectors.changedId(store.getState())
      const startCoreChangedId = coreSelectors.changedId(store.getState())

      dispatch(coreActions.toggleTouch(isTouch))

      // Берем состояния стейтов после изменений страницы
      const finishHeaderChangedId = headerSelectors.changedId(store.getState())
      const finishFooterChangedId = footerSelectors.changedId(store.getState())
      const finishCoreChangedId = coreSelectors.changedId(store.getState())

      // Если стейты страницей не изменялись, значит ставим странице дефолтные стейты
      if (startHeaderChangedId === finishHeaderChangedId) {
        dispatch(headerActions.setColor('black'))
        dispatch(headerActions.setFade('white'))
        dispatch(headerActions.toggleNavigation(true))
      }
      if (startFooterChangedId === finishFooterChangedId) {
        dispatch(footerActions.setColor('silver'))
      }
      if (startCoreChangedId === finishCoreChangedId) {
        dispatch(coreActions.toggleLayout(true))
      }

      dispatch(headerActions.toggleHeader(true))

      const endTimestamp = Date.now()

      if (ctx.pathname.includes('/uz/[id]')) {
        console.log(`AppPage end ${endTimestamp - startTime}ms`)
        logsManager.callLog(`Uz page APP PROMISES`)
      }

      timingTelemetry.end('AppPage getInitialProps')

      const { pageProps } = await App.getInitialProps(appContext)

      return {
        pageProps: {
          ...pageProps,
          isTouch,
        },
      }
    }
)

export default wrapper.withRedux(
  withVictoryCorp(
    Router,
    schoolPages
  )(withGa(analyticsIds.ga, Router)(withGTM(analyticsIds.gtm)(CustomApp)))
)
