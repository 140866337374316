import { EModButtonColor, EModButtonView, IGetProps } from './types'

export const getProps: IGetProps = (props) => {
  const newProps = { ...props }

  if (newProps.href) {
    delete newProps.type
  } else if (!newProps.type) {
    newProps.type = 'button'
  }

  if (!newProps.as) {
    newProps.as = newProps.href ? 'a' : 'button'
  }

  newProps.href = newProps.disabled ? null : newProps.href

  return newProps
}

export const getColorProgress = (
  color?: keyof typeof EModButtonColor,
  view?: keyof typeof EModButtonView | string
): 'white' | 'black' => {
  if (color === 'black' && view !== 'outline') return 'white'

  if (color === 'plum' || color === 'navy') return 'white'

  return color === 'blue' && view !== 'outline' ? 'white' : 'black'
}
