import { FC, Fragment } from 'react'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import Image from 'next/image'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import { useAllowedChannels } from '@ucheba/utils/hooks/useAllowedChannels'
import VkIcon from '@ucheba/ui/icons/icon__vk_square.svg'
import TgIcon from '@ucheba/ui/icons/icon__telegram-filled.svg'
import { EMessengerChannel, EMessengerType } from '@ucheba/store/profile/types'
import styled from 'styled-components'
import { useVkPermissionNotice } from '../bll'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LeftImage from '../assets/img__notice_desktop.png'

import { linkToTgSubscribe, linkToVkSubscribe } from '../constants'

const GridColSC = styled(GridCol)`
  display: flex;
  align-items: flex-end;
`

const VkPermissionNoticeDesktop: FC = () => {
  const allowedMessangers = useAllowedChannels([
    EMessengerChannel.vk_ucheba,
    EMessengerChannel.telegram_ucheba,
  ])
  const { notice, title, description, buttons, removeStorage } = useVkPermissionNotice({
    allowedMessangers,
  })

  return (
    <Notice
      hideProgress
      open={notice.open}
      color='navy'
      onClose={() => {
        removeStorage()
        notice.removeNotice()
      }}
      spacing='medium'
    >
      <Heading h3={title} />

      <Grid spacing='uxsmall' justifyContent='space-between'>
        <GridCol width='344px'>
          <Text>{description}</Text>

          <Spacing spacing='small' />
          {buttons.map((button) => {
            return (
              <Fragment key={button.id}>
                <Button
                  color='gold'
                  target='_blank'
                  href={button.id === 'vk' ? linkToVkSubscribe : linkToTgSubscribe}
                  StartIcon={button.id === 'vk' ? <VkIcon /> : <TgIcon />}
                  onClick={() => {
                    notice.removeNotice()
                    // отправляем аналитику клика-перехода в яндекс-метрику
                    analyticEvent({
                      targets: [EAnalyticEventTargets.ym, EAnalyticEventTargets.ur],
                      eventName: EAnalyticEvents.vkNoticeClickLead,
                      section: EAnalyticSections.vkNotice,
                    })
                  }}
                >
                  {button.text}
                </Button>
                <Spacing spacing='small' />
              </Fragment>
            )
          })}
        </GridCol>

        <GridColSC width='114px'>
          <Image src={LeftImage} width={114} height={166} />
        </GridColSC>
      </Grid>
    </Notice>
  )
}

export default VkPermissionNoticeDesktop
