import apiThunk, { api } from '@ucheba/utils/helpers/api'
import { sliceName } from './constants'

const pay = apiThunk(`${sliceName}/pay`, async (params) => {
  const response = await api.post(
    `client-products/requests/payment/manual`,
    params?.data,
    params
  )

  return response.data
})

export default { pay }
