import { isBrowser } from '../core'

export const init = (): void => {
  if (isBrowser) {
    const script = document.createElement('script')
    script.id = 'victory-corp'
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://victorycorp.ru/index.php?ref=${
      document.referrer
    }&page=${encodeURIComponent(window.location.href)}`

    document.body.appendChild(script)
  }
}
