import React, { FC } from 'react'
import { IFooterNavLinksProps } from '../types'

export const FooterNavLinks: FC<IFooterNavLinksProps> = ({ list }) => {
  if (list) {
    return (
      <>
        {list.map((item, key) => {
          if (item.href) {
            return (
              <a href={item.href} key={key}>
                {item.name}
              </a>
            )
          }

          return <span key={key} dangerouslySetInnerHTML={{ __html: item.name }} />
        })}
      </>
    )
  }

  return (
    <>
      <a href='/for-clients'>Учебным заведениям</a>

      <a href='https://ucheba.ru/license.pdf' target='_blank' rel='noreferrer'>
        Лицензионное соглашение
      </a>

      <a href='/agreement'>Пользовательское соглашение</a>
    </>
  )
}
