import type { ServerResponse } from 'http'

type Timing = {
  name: string
  start: number | null
  end: number | null
  time: number | null
}

// Оюъект для измерения времени выполнения различных операций, ведёт их учёт и позволяет
// логировать их в консоль или передавать в заголовки HTTP-ответа.
export const timingTelemetry = new (class {
  // Массив для хранения данных о замерах времени выполнения
  private timings: Array<Timing> = []

  // Переменная для хранения общего времени выполнения
  private time: number | null = null

  /**
   * Начинает замер времени для указанного имени
   * @param name - Название замера
   */
  start(name: string): void {
    const timing = this.timings.find((t) => t.name === name)

    if (timing) {
      // Если замер с таким именем уже существует, обновляем его стартовое время
      timing.start = performance.now()
      timing.end = null
      timing.time = null
    } else {
      // Если замер отсутствует, добавляем новый
      this.timings.push({
        name,
        start: performance.now(),
        end: null,
        time: null,
      })
    }

    // Если общий таймер ещё не запущен, запускаем его
    if (typeof this.time !== 'number') {
      this.time = performance.now()
    }
  }

  /**
   * Завершает замер времени для указанного имени
   * @param name - Название замера
   */
  end(name: string): void {
    const timing = this.timings.find((t) => t.name === name)

    if (timing && timing.start) {
      timing.end = performance.now()
      timing.time = timing.end - timing.start
    }

    if (this.time !== null) {
      this.time = performance.now() - this.time
    }
  }

  /**
   * Логирует все замеры в консоль
   * @param label - Дополнительная метка для лога
   */
  log(label?: string): void {
    const log = this.timings
      .map((timing, index) =>
        timing.time !== null
          ? `[${index + 1}]: ${timing.name} - ${timing.time.toFixed(2)}ms`
          : `[${index + 1}]: ${timing.name} - not called end()`
      )
      .join('; ')

    console.log(`📈 Timing telemetry${label ? ` - ${label}` : ''}: ${log}`)
  }

  /**
   * Возвращает массив всех замеров
   */
  get(): Timing[] {
    return [...this.timings] // Возвращаем копию массива, чтобы избежать внешних изменений
  }

  /**
   * Устанавливает заголовок `server-timing` в ответе сервера
   * @param res - Ответ сервера (если передан)
   */
  setServerTiming(res?: ServerResponse): void {
    if (!res) return

    const result = this.timings
      .filter((t) => t.time !== null)
      .map((t) => `${t.name.replace(/\s+/g, '.')};dur=${t.time!.toFixed(2)}`)

    const total = this.timings.reduce((sum, t) => sum + (t.time ?? 0), 0)

    result.push(`total;dur=${total}`)

    res.setHeader('server-timing', result)
  }

  /**
   * Возвращает общее время выполнения в виде строки
   */
  getTime(): string | null {
    return this.time !== null ? `${this.time.toFixed(2)}ms` : null
  }
})()
