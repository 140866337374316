/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import {
  IClientProductsRequestsPaymentManualEntity,
  IClientProductsRequestsPaymentManualState,
} from './types'
import { extraReducersResponse, responseInitialState } from '../../../../utils/response'
import { sliceName } from './constants'
import thunks from './thunks'
import { ELoading } from '../../../../utils/response/types'

export default createSlice({
  name: sliceName,

  initialState: {
    confirmationUrl: '' as IClientProductsRequestsPaymentManualEntity['confirmationUrl'],
    ...responseInitialState,
  },

  reducers: {
    resetLoading: (state): void => {
      state.loading = ELoading.idle
    },
  },

  extraReducers: (builder) => {
    extraReducersResponse<
      IClientProductsRequestsPaymentManualState[typeof sliceName],
      IClientProductsRequestsPaymentManualEntity
    >({
      builder,
      thunk: thunks.pay,
      fulfilled: (state, { payload }) => {
        state.confirmationUrl = payload.confirmationUrl
      },
    })
  },
})
