import {
  IModBlock,
  IModDisabled,
  IModEndIcon,
  IModJustifyContent,
  IModProgress,
  IModStartIcon,
} from '@ucheba/utils/helpers/styles/mod.types'
import {
  IPropAs,
  IPropChildren,
  IPropCss,
  IPropHref,
  IPropId,
  IPropOnClick,
  IPropRef,
  IPropTitle,
  IPropWidth,
} from '@ucheba/utils/helpers/components/prop.types'
import React from 'react'

export interface IButtonProps
  extends IPropChildren,
    IPropAs<'a' | 'button'>,
    IPropOnClick<() => void>,
    IPropRef<HTMLButtonElement>,
    IPropHref,
    IPropCss,
    IPropId,
    IPropTitle,
    IModStartIcon,
    IModEndIcon,
    IModProgress,
    IModBlock,
    IModButtonColor,
    IModButtonSize,
    IModButtonView,
    IModButtonIcon,
    IModButtonShape,
    IModDisabled,
    IPropWidth,
    IModJustifyContent {
  /** Тип кнопки. Не использовать вместе с `href`. */
  type?: string

  rel?: string

  target?: string

  /* Сбрасывает высоту кнопки */
  resetHeight?: boolean

  /* Позиционирование текста кнопки */
  textAlign?: 'left' | 'center' | 'right'

  conditionalWrapper?: (child: JSX.Element) => JSX.Element
}

export interface IGetProps extends IPropRef<HTMLButtonElement> {
  (props: Partial<Pick<IButtonProps, 'type' | 'href' | 'as' | 'disabled'>>): any
}

export enum EModButtonColor {
  blue = 'color=blue',
  gold = 'color=gold',
  black = 'color=black',
  coffee = 'color=coffee',
  green = 'color=green',
  gray25 = 'color=gray25',
  gray5 = 'color=gray5',
  orange = 'color=orange',
  emerald = 'color=emerald',
  white = 'color=white',
  maroon = 'color=maroon',
  pine = 'color=pine',
  pink = 'color=pink',
  red = 'color=red',
  lime = 'color=lime',
  navy = 'color=navy',
  plum = 'color=plum',
  rose = 'color=rose',
  steel = 'color=steel',
}

export interface IModButtonColor {
  /** Цвет */
  color?: keyof typeof EModButtonColor
}

//

export enum EModButtonSize {
  xxsmall = 'size=xxsmall',
  xsmall = 'size=xsmall',
  small = 'size=small',
  medium = 'size=medium',
}

export interface IModButtonSize {
  /** Размер кнопки. */
  size?: keyof typeof EModButtonSize
}

//

export enum EModButtonView {
  default = 'view=default',
  link = 'view=link',
  outline = 'view=outline',
}

export interface IModButtonView {
  /** Вид кнопки:
   * `link` – в виде ссылки,
   * `outline` – без фона и с бордером */
  view?: keyof typeof EModButtonView
}

//

export enum EModButtonIcon {
  true = 'icon=true',
  false = 'icon=false',
}

export interface IModButtonIcon {
  /** Адаптация под иконку переданную в `children` */
  icon?: boolean
}

//

export enum EModButtonShape {
  circle = 'shape=circle',
  rectangle = 'shape=rectangle',
}

export interface IModButtonShape {
  /** Форма кнопки. */
  shape?: keyof typeof EModButtonShape
}

export type TButtonHoverColor =
  | 'cobalt'
  | 'tangerine'
  | 'coffee'
  | 'coffee60'
  | 'green'
  | 'mandarin'
  | 'maroon'
  | 'pine'
  | 'pink'
  | 'red'
  | 'plum'
  | 'raspberry'
  | 'acid'

export type TButtonTextColor = 'black' | 'white' | 'pink' | 'plum'
