import { IThemeVariables } from './types'

/**
 *  Переменные стилей по умолчанию.
 *  Каждую из них можно переопределить в настройках темы сервиса.
 */

export enum EColor {
  transparent = 'rgba(0, 0, 0, 0)',
  coffee = 'rgba(43, 8, 0, 1)',
  coffee9 = 'rgba(43, 8, 0, 0.09)',
  coffee15 = 'rgba(43, 8, 0, 0.15)',
  coffee25 = 'rgba(43, 8, 0, 0.25)',
  coffee60 = 'rgba(43, 8, 0, 0.60)',
  cerulean = 'rgba(0, 100, 206, 1)',
  mandarin = 'rgba(255, 66, 0, 1)',
  orange = 'rgba(255, 106, 0, 1)',
  orange5 = 'rgba(255, 106, 0, 0.05)',
  orange50 = 'rgba(255, 106, 0, 0.5)',
  orange15 = 'rgba(255, 106, 0, 0.15)',
  orange20 = 'rgba(255, 106, 0, 0.20)',
  orange30 = 'rgba(255, 106, 0, 0.30)',
  orange25 = 'rgba(255, 106, 0, 0.25)',
  dustyorange = 'rgba(246, 105, 53, 1)',
  maize = 'rgba(237, 185, 83, 1)',
  tangerine = 'rgba(255, 206, 8, 1)',
  tangerine60 = 'rgba(255, 206, 8, 0.6)',
  gold = 'rgba(255, 229, 84, 1)',
  gold25 = 'rgba(255, 229, 84, 0.25)',
  gold30 = 'rgba(255, 229, 84, 0.3)',
  yellow = 'rgba(255, 247, 159, 1)',
  maroon = 'rgba(52, 0, 20, 1)',
  maroon9 = 'rgba(52, 0, 20, .09)',
  maroon15 = 'rgba(52, 0, 20, .15)',
  maroon25 = 'rgba(52, 0, 20, 0.25)',
  maroon60 = 'rgba(52, 0, 20, 0.60)',
  red = 'rgba(255, 8, 41, 1)',
  raspberry = 'rgba(255, 0, 99, 1)',
  pink = 'rgba(254, 47, 128, 1)',
  pink15 = 'rgba(254, 47, 128, 0.15)',
  pink20 = 'rgba(254, 47, 128, 0.20)',
  pink30 = 'rgba(254, 47, 128, 0.30)',
  plum = 'rgba(130, 80, 255, 1)',
  plum5 = 'rgba(130, 80, 255, 0.05)',
  plum20 = 'rgba(130, 80, 255, 0.20)',
  plum30 = 'rgba(130, 80, 255, 0.30)',
  purp = 'rgba(172, 0, 234, 1)',
  pink5 = 'rgba(254, 47, 128, 0.05)',
  pink50 = 'rgba(254, 47, 128, 0.50)',
  iris = 'rgba(90, 42, 210, 1)',
  flamingo = 'rgba(255, 201, 241, 1)',
  rose = 'rgba(254, 214, 244, 1)',
  rose30 = 'rgba(254, 214, 244, 0.3)',
  rose50 = 'rgba(254, 214, 244, 0.5)',
  pine = 'rgba(56, 66, 2, 1)',
  pine9 = 'rgba(56, 66, 2, 0.09)',
  pine15 = 'rgba(56, 66, 2, 0.15)',
  pine25 = 'rgba(56, 66, 2, 0.25)',
  pine60 = 'rgba(56, 66, 2, 0.60)',
  emerald = 'rgba(21, 88, 1, 1)',
  green = 'rgba(28, 117, 3, 1)',
  green5 = 'rgba(28, 117, 3, 0.05)',
  green15 = 'rgba(28, 117, 3, 0.15)',
  acid = 'rgba(210, 247, 17, 1)',
  acid80 = 'rgba(210, 247, 17, 0.8)',
  lime = 'rgba(227, 255, 79, 1)',
  lime30 = 'rgba(227, 255, 79, .30)',
  teal = 'rgba(99, 177, 188, 1)',
  lemon = 'rgba(243, 254, 0, 1)',
  navy = 'rgba(0, 5, 55, 1)',
  cobalt = 'rgba(0, 100, 206, 1)',
  cobalt30 = 'rgba(0, 100, 206, 0.3)',
  cobalt20 = 'rgba(0, 100, 206, 0.2)',
  cobalt60 = 'rgba(0, 100, 206, 0.60)',
  blue = 'rgba(29, 138, 254, 1)',
  blue10 = 'rgba(29, 138, 254, 0.1)',
  blue15 = 'rgba(29, 138, 254, 0.15)',
  blue30 = 'rgba(29, 138, 254, 0.30)',
  blue50 = 'rgba(29, 138, 254, 0.50)',
  blue70 = 'rgba(29, 138, 254, .7)',
  silver = 'rgba(247, 249, 252, 1)',
  steel = 'rgba(145, 155, 170, 1)',
  white = 'rgba(255, 255, 255, 1)',
  white9 = 'rgba(255, 255, 255, 0.09)',
  white15 = 'rgba(255, 255, 255, 0.15)',
  white25 = 'rgba(255, 255, 255, 0.25)',
  white50 = 'rgba(255, 255, 255, 0.5)',
  white60 = 'rgba(255, 255, 255, 0.6)',
  white70 = 'rgba(255, 255, 255, 0.7)',
  black = 'rgba(3, 15, 35, 1)',
  black9 = 'rgba(3, 15, 35, 0.09)',
  gray2 = 'rgba(3, 15, 35, .02)',
  gray5 = 'rgba(3, 15, 35, .05)',
  gray9 = 'rgba(3, 15, 35, .09)',
  gray15 = 'rgba(3, 15, 35, 0.15)',
  gray25 = 'rgba(3, 15, 35, 0.25)',
  gray30 = 'rgba(3, 15, 35, 0.30)',
  gray40 = 'rgba(3, 15, 35, 0.4)',
  gray50 = 'rgba(3, 15, 35, 0.5)',
  gray60 = 'rgba(3, 15, 35, 0.6)',
}

export enum ETransparentColor {
  coffee = 'rgba(43, 8, 0, 0)',
  mandarin = 'rgba(255, 66, 0, 0)',
  orange = 'rgba(255, 106, 0, 0)',
  dustyorange = 'rgba(246, 105, 53, 0)',
  maize = 'rgba(237, 185, 83, 0)',
  tangerine = 'rgba(255, 206, 8, 0)',
  gold = 'rgba(255, 229, 84, 0)',
  gold25 = 'rgba(255, 229, 84, 0.3)',
  yellow = 'rgba(255, 247, 159, 0)',
  maroon = 'rgba(52, 0, 20, 0)',
  red = 'rgba(255, 8, 41, 0)',
  pink = 'rgba(254, 47, 128, 0)',
  plum = 'rgba(130, 80, 255, 0)',
  plum5 = 'rgba(130, 80, 255, 0)',
  flamingo = 'rgba(255, 201, 241, 0)',
  rose = 'rgba(254, 214, 244, 0)',
  pine = 'rgba(56, 66, 2, 0)',
  emerald = 'rgba(21, 88, 1, 0)',
  green = 'rgba(28, 117, 3, 0)',
  acid = 'rgba(210, 247, 17, 0)',
  lime = 'rgba(227, 255, 79, 0)',
  navy = 'rgba(0, 5, 55, 0)',
  cobalt = 'rgba(0, 100, 206, 0)',
  blue = 'rgba(29, 138, 254, 0)',
  silver = 'rgba(247, 249, 252, 0)',
  steel = 'rgba(145, 155, 170, 0)',
  white = 'rgba(255, 255, 255, 0)',
  black = 'rgba(3, 15, 35, 0)',
  teal = 'rgb(99, 177, 188, 0)',
}

export enum EColorBgText {
  rose = 'maroon',
  rose30 = 'maroon',
  rose50 = 'red',
  lime = 'pine',
  lime30 = 'pine',
  blue = 'white',
  blue10 = 'black',
  blue70 = 'red',
  gold = 'coffee',
  gold25 = 'coffee',
  navy = 'white',
  silver = 'black',
  white = 'black',
  black = 'white',
  yellow = 'orange',
  acid = 'emerald',
  flamingo = 'maroon',
  plum = 'white',
  plum5 = 'white',
  orange = 'white',
  teal = 'white',
  gray60 = 'white',
  pink = 'white',
  raspberry = 'white',
}

export enum EColorBgTextTransparent {
  blue = 'white70',
  gold = 'coffee60',
  silver = 'gray60',
  acid = 'pine60',
  flamingo = 'maroon60',
  plum = 'white70',
  plum5 = 'white70',
}

export enum EColorHover {
  blue = 'cobalt',
  gray5 = 'gray15',
  silver = 'gray15',
  pink = 'raspberry',
  gold = 'tangerine',
  orange = 'mandarin',
  plum = 'iris',
  coffee = 'maroon',
  maroon = 'coffee',
  rose = 'flamingo',
}

export enum ESpacing {
  uxsmall = '2px',
  usmall = '4px',
  xxsmall = '8px',
  xsmall = '12px',
  small = '16px',
  medium = '20px',
  large = '24px',
  xlarge = '32px',
  xxlarge = '40px',
  ularge = '56px',
  uxlarge = '64px',
  uxxlarge = '80px',
  container = '16px',
}

export enum EFontSize {
  h1 = '40px',
  h1small = '36px',
  h2 = '32px',
  h3 = '24px',
  h4 = '18px',
  h5 = '16px',
  h6 = '14px',
  large = '18px',
  medium = '16px',
  small1 = '14px',
  small2 = '14px',
  xsmall1 = '12px',
  xsmall2 = '12px',
}

export enum ELineHeight {
  h1 = '48px',
  h2 = '40px',
  h3 = '32px',
  h4 = '24px',
  h5 = '24px',
  h6 = '22px',
  large = '24px',
  medium = '24px',
  small1 = '22px',
  small2 = '18px',
  xsmall1 = '18px',
  xsmall2 = '12px',
}

export enum EFontWeight {
  regular = '400',
  medium = '500',
  semibold = '600',
  bold = '700',
  extrabold = '800',
}

export enum EFontFamily {
  proximaNova = 'proxima-nova, Arial, Helvetica, sans-serif',
}

export enum ERadius {
  small = '2px',
  medium = '4px',
  large = '8px',
  xlarge = '16px',
}

export enum EShadow {
  small = `0 2px 5px 0 rgba(3, 15, 35, 0.15)`,
  medium = `0 15px 20px 0 rgba(3, 15, 35, 0.1), 0 0 10px 0 rgba(3, 15, 35, 0.05)`,
  large = `0 25px 30px 0 rgba(3, 15, 35, 0.1), 0 0 20px 0 rgba(3, 15, 35, 0.05)`,
}

export enum EBreakpoint {
  uxs = '0px',
  xs = '480px',
  sm = '720px',
  md = '1024px',
  lg = '1200px',
}

export enum EContainer {
  uxs = '100%',
  xs = '100%',
  sm = '720px',
  md = '1024px',
  lg = '1200px',
}

export enum EDuration {
  slow = '0.4s',
  medium = '0.3s',
  fast = '0.15s',
}

/** Класс для формирования темы в проекте с возможностью переопредиления значения переменных. */
export default class Theme {
  private defaultVariables: Required<IThemeVariables> = {
    color: EColor,
    spacing: ESpacing,
    fontSize: EFontSize,
    lineHeight: ELineHeight,
    fontWeight: EFontWeight,
    fontFamily: EFontFamily,
    radius: ERadius,
    shadow: EShadow,
    breakpoint: EBreakpoint,
    duration: EDuration,
    container: EContainer,
  }

  constructor(newVariables?: IThemeVariables) {
    this.replaceColors(newVariables)
  }

  private replaceColors(colors: IThemeVariables = {}): void {
    Object.keys(colors).forEach((part) => {
      this.defaultVariables[part] = { ...this.defaultVariables[part], ...colors[part] }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  private getValueByTheme(kind: string, variable: string): (props: any) => string {
    return (props): string => props.theme[kind][variable]
  }

  private getVariablesByKind(kind: string, enumKind): any {
    return Object.keys(enumKind).reduce((accKind, variable) => {
      return {
        ...accKind,
        [variable]: this.getValueByTheme(kind, variable),
      }
    }, {})
  }

  get variables(): Required<IThemeVariables> {
    return this.defaultVariables
  }

  get styledVariables(): Required<IThemeVariables> {
    return Object.keys(this.variables).reduce(
      (accVars, kind) => {
        return {
          ...accVars,
          [kind]: this.getVariablesByKind(kind, this.variables[kind]),
        }
      },
      <Required<IThemeVariables>>{}
    )
  }
}

export const sv = new Theme().styledVariables
