import { sliceName } from './constants'
import { getStateError, getStateItem } from '../../../../utils/core'
import { IClientProductsRequestsPaymentManualState } from './types'

export default {
  confirmationUrl: getStateItem<
    IClientProductsRequestsPaymentManualState,
    IClientProductsRequestsPaymentManualState[typeof sliceName]['confirmationUrl']
  >(sliceName, 'confirmationUrl'),

  loading: getStateItem<
    IClientProductsRequestsPaymentManualState,
    IClientProductsRequestsPaymentManualState[typeof sliceName]['loading']
  >(sliceName, 'loading'),

  error: getStateError<IClientProductsRequestsPaymentManualState>(sliceName),
}
