import { IPageProps } from '@ucheba/utils/helpers/components/types'
import { IHeaderOffers } from '@ucheba/store/page-data/for-abiturients/headerOffers/types'
import { EApplicationFunnels } from '../../../../components/AppliactionForm/types'
import { ISchoolDataContent } from '../../school/index/bll/useConvertSchoolData'

export interface IIndexEgePageProps<T = never>
  extends IPageProps<ISchoolDataContent, T> {}

export interface IModIsActive {
  /** Активен ли элемент */
  isActive: boolean
}

export enum EIconName {
  vk = 'vk',
  instagram = 'instagram',
  university = 'university',
  scales = 'scales',
  graduationCap = 'graduationCap',
  listView = 'listView',
  search = 'search',
  like = 'like',
  bowTie = 'bowTie',
}

export type TIconName = keyof typeof EIconName

export enum EGroups {
  standard = 14,
  mini = 15,
}

export interface IUseSelectEgeProduct {
  (): {
    selectGroup: (
      data?: IUseSelectEgeProductData,
      funnel?: EApplicationFunnels,
      typeRequest?: string,
      subjectId?: number | string,
      step?: string,
      eventsId?: number | string,
      withPayment?: boolean
    ) => void
    payProduct: (data?: IUseSelectEgeProductData) => void
  }
}

interface IUseSelectEgeProductData {
  productId: number
  withPayment?: true
  count?: number
}

export enum ERequestQuery {
  from = 'from',
  referralCode = 'referral_code',
  specialOffer = 'special_offer',
  lovkoProClickId = 'lovko_pro_clickid',
  edpartnersClickId = 'cl_uid',
}

export enum ERequestSource {
  egeMarathon = 'ege-marathon',
}

export enum EFreeServicesType {
  openLesson = '1',
  testEGE = '2',
}

export enum IFreeServicesQuery {
  freeServicesType = 'freeServicesType',
}

export enum ETypeRequest {
  ege_lead_free_lesson = 'ege_lead_free_lesson',
  ege_lead_free_test = 'ege_lead_free_test',
  ege_lead_consult = 'ege_lead_consult',
  ege_lead_request = 'ege_lead_request',
  oge_lead_request = 'oge_lead_request',
  proforientation_request = 'proforientation_request',
  select_institution_request = 'select_institution_request',
}

export interface IIndexEgePageHeroProps {
  totalDiscount: number
  headerOffers?: IHeaderOffers
  timer?: boolean
}
